import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/planting.sass"

import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'

const Planting = () => (
<div className="info-page planting">

    <div className="info-page-intro">
        <h1>Planting</h1>
        <p>Helping you keep your property healthy, clean, and safe.</p>
    </div>
    <div className="planting-image">
        <Carousel>
            <Carousel.Item interval={2500}>
                <Image filename="flowers-top-view.jpeg" />
            </Carousel.Item>
            <Carousel.Item interval={2500}>
                <Image filename="planting-with-hands.jpeg" />                
            </Carousel.Item>
        </Carousel>
    </div>

    <div className="discription">

        <p className="landscape-statement">
            At Kevins Fencing and landscaping, we’ll do whatever is needed to get your property ready for the next season.
            We come with years of experience and a reputation of doing exceptional jobs. 
            Getting your landscape back into shape after the end of the season can be a big job, and we have the team to tackle just that.
        </p>

    </div>
</div>

)

export default Planting